<template>
  <figure
    data-el="ui-social-feed-item"
    class="flex w-full flex-col bg-white shadow-md transition-shadow hover:shadow-lg"
  >
    <a
      :href="props.url"
      target="_blank"
      class="aspect-square cursor-pointer overflow-hidden"
    >
      <UiImage
        v-if="props.image"
        :src="props.image"
        :alt="props.title"
        width="312"
        height="312"
        class="size-full object-cover object-left-top"
      />
      <picture v-else>
        <source
          type="image/webp"
          :srcset="`${assetsURL}/images/sm-placeholder.webp`"
        >
        <source
          type="image/avif"
          :srcset="`${assetsURL}/images/sm-placeholder.avif`"
        >
        <img
          :src="`${assetsURL}/images/sm-placeholder.jpg`"
          loading="lazy"
          fetchpriority="low"
          decoding="async"
          alt=""
        >
      </picture>
    </a>

    <!-- content -->
    <div class="flex grow flex-col px-7 pb-7 pt-5">
      <div class="my-4 flex items-center gap-4">
        <UiIcon
          name="facebook-feed"
          width="50"
          height="50"
          :lazy-img="true"
        />
        <div>
          <p class="line-clamp-2 font-bold text-primary">
            {{ props.title }}
          </p>
          <span class="text-sm text-blue-100">{{ props.date }}</span>
        </div>
      </div>
      <a
        :href="props.url"
        target="_blank"
        class="m-0 hidden grow cursor-pointer border-b border-[#cccccc] text-sm text-black xl:block xl:border-y xl:py-3.5"
      >
        <p
          v-if="props.message"
          class="line-clamp-[7]"
        >
          {{ props.message }}
        </p>
      </a>
      <!-- actions -->
      <div class="flex justify-between pt-5 align-bottom xl:py-3">
        <p class="flex gap-2">
          <UiIcon
            name="like-feed"
            :width="21"
            :height="19.3"
            :lazy-img="true"
          />
          <span>{{ props.likes }}</span>
        </p>
        <p class="flex gap-2">
          <UiIcon
            name="message-feed"
            :width="19"
            :height="19"
            class="relative self-end"
            :lazy-img="true"
          />
          <span>{{ props.comments }}</span>
        </p>
        <a
          class="flex gap-2"
          :href="shareUrl"
          target="_blank"
          :title="props.labels.share"
        >
          <UiIcon
            name="share-feed"
            :width="21"
            :height="19.3"
            class="relative self-end"
            :lazy-img="true"
          />
        </a>
      </div>
    </div>
  </figure>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import UiImage from '../UiImage/UiImage.vue'
import UiIcon from '../UiIcon/UiIcon.vue'

interface UiSocialFeedItemLabels {
  share: string
}

interface UiSocialFeedItem {
  uid: string | number
  url: string
  image: string
  title: string
  date: string | object
  message: string
  likes: number
  comments: number
  labels: UiSocialFeedItemLabels
}

const props = withDefaults(defineProps<UiSocialFeedItem>(), {
  url: '',
  date: '',
  message: '',
  likes: 0,
  comments: 0,
})

const assetsURL = globalThis?.__publicAssetsURL?.() ?? ''

const shareUrl = computed(
  () =>
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      props.url,
    )}`,
)
</script>
